import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../css/images/logo.png";
import axios from "axios";
import "./../css/sign-in.css";

function SignIn() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        {
          email: data.get("email"),
          password: data.get("password"),
        }
      );

      // Clear previously stored token
      localStorage.removeItem("token");

      // Store new token in localStorage
      localStorage.setItem("token", response.data.token);

      setLoggedIn(true);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard", { replace: true });
    }
  }, [loggedIn, navigate]);

  return (
    <div className="container">
      <div className="left"></div>
      <div className="right">
        <div className="form-container">
          <div className="logo-signin">
            <img src={logo} alt="Logo" />
            <h2>Vacanzi Admin Panel</h2>
          </div>
          <p className="login">Login</p>
          <form onSubmit={handleSubmit} noValidate>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" required />
            </div>
            <button className="signinButton" type="submit">Sign In</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
