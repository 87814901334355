import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Space,
  notification,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import MiniDrawer from "../components/sidebar";
import "../css/main.css";
import Breadcrumb from "../components/BreadCrumbs";

const { Title } = Typography;

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [admins, setAdmins] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getadmins`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setAdmins(response.data);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      duration: 3, // Notification duration in seconds
    });
  };

  const handleSubmit = async () => {
    try {
      if (editMode) {
        // Update existing admin
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getadmins/${editId}`,
          { email, phone, fullName: name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        openNotificationWithIcon("success", response.data.message);
      } else {
        // Register new admin
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/register`,
          { email, password, phone, fullName: name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        openNotificationWithIcon("success", response.data.message);
      }
      setEmail("");
      setPassword("");
      setPhone("");
      setName("");  // Clear name field
      setEditMode(false);
      setEditId("");
      fetchAdmins(); // Refresh the admin list
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      openNotificationWithIcon(
        "error",
        error.response?.data?.message || "Server error"
      );
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getadmins/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      openNotificationWithIcon("success", "Admin deleted successfully");
      fetchAdmins(); // Refresh the admin list
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting admin:", error);
      openNotificationWithIcon("error", "Failed to delete admin");
      setDeleteDialogOpen(false);
    }
  };

  const handleEdit = (admin) => {
    setEmail(admin.email);
    setPhone(admin.phone);
    setName(admin.fullName); // Set name for editing
    setEditId(admin._id);
    setEditMode(true);
    setOpen(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditId("");
    setEmail("");
    setPassword("");
    setPhone("");
    setName(""); // Clear name field
  };

  return (
    <div>
      <Breadcrumb />
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={() => setOpen(true)}
            style={{
              backgroundColor: "transparent",
              color: "#22004C",
              border: "2px solid #22004C",
              borderRadius: "20px",
              ":hover": { backgroundColor: "#22004C", color: "white" },
            }}
          >
            Admin Registration
          </Button>
        </div>
        
        <Table dataSource={admins} rowKey="_id">
          <Table.Column title="Name" dataIndex="fullName" key="fullName" />
          <Table.Column title="Email" dataIndex="email" key="email" />
          <Table.Column title="Phone" dataIndex="phone" key="phone" />
          <Table.Column
            title="Actions"
            key="actions"
            render={(text, record) => (
              <Space size="middle">
                <Tooltip title="Edit">
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => handleEdit(record)}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteClick(record._id)}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>

        <Modal
          title={editMode ? "Edit Admin" : "Register Admin"}
          visible={open}
          onCancel={handleClose}
          footer={[
            <Button key="cancel" onClick={handleClose}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}>
              {editMode ? "Update" : "Register"}
            </Button>,
          ]}
        >
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item label="Name" required>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label="Email" required>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            {!editMode && (
              <Form.Item label="Password" required>
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>
            )}
            <Form.Item label="Phone" required>
              <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Confirm Deletion"
          visible={deleteDialogOpen}
          onCancel={() => setDeleteDialogOpen(false)}
          footer={[
            <Button key="cancel" onClick={() => setDeleteDialogOpen(false)}>
              Cancel
            </Button>,
            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => handleDelete(deleteId)}
            >
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this admin?</p>
        </Modal>
      </div>
    </div>
  );
};

export default Admin;
