import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const StatCardContainer = styled(Card)(({ theme }) => ({
  minWidth: 275,
  padding: theme.spacing(2),
  position: 'relative',
  overflow: 'visible',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adding box shadow to the card
}));

const StatIconContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#423BFA',
  borderRadius: '8%',
  padding: theme.spacing(1),
  position: 'absolute',
  top: -25,
  left: 25,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 60,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adding box shadow to the icon container
}));

const StatIcon = styled('div')(({ theme }) => ({
  fontSize: 40,
  color: 'white',
}));

const StatContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const StatTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  fontSize: '1rem',
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

const StatValue = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  fontSize: '2rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const StatChange = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontSize: '1rem',
  fontWeight: 400,
  color: theme.palette.success.main,
  marginTop: theme.spacing(2),
}));

function StatCard({ title, value, icon, color, change }) {
  return (
    <StatCardContainer>
      <StatIconContainer>
        <StatIcon>{icon}</StatIcon>
      </StatIconContainer>
      <StatContent>
        <StatTitle>{title}</StatTitle>
        <StatValue>{value}</StatValue>
        <Divider />
        <StatChange>{change}</StatChange>
      </StatContent>
    </StatCardContainer>
  );
}

export default StatCard;
