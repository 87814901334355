import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Correct import for jwtDecode
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
} from "@mui/material";
import Breadcrumb from "../components/BreadCrumbs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import UploadCard from "../components/uploadTracker";

const CreateJobSeeker = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    appliedDesignation: "",
    appliedJobLocation: "",
    currentCompany: "",
    currentDesignation: "",
    totalExp: "",
    currentCtc: "",
    noticePeriod: "",
    experienceType: "", // Added field
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [recruiterName, setRecruiterName] = useState("");
  const [sourcedBy, setSourcedBy] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin"); // Redirect to your sign-in page if token is missing
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      setRecruiterName(decodedToken.fullName);
      setSourcedBy(decodedToken._id);
    } catch (error) {
      console.error("Error decoding token:", error);
      navigate("/signin"); // Redirect to sign-in if token is invalid
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/createjobseeker`,
        {
          ...formData,
          role: "jobseeker",
          sourcedBy,
          recruiterName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setOpen(true);
        // Reset formData to initial state after successful creation
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          appliedDesignation: "",
          appliedJobLocation: "",
          currentCompany: "",
          currentDesignation: "",
          totalExp: "",
          currentCtc: "",
          noticePeriod: "",
          experienceType: "", // Reset the new field
        });
        console.log(formData);
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          navigate("/signin"); // Redirect to sign-in if token is invalid or expired
        }
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
      }}
    >
      <Breadcrumb mr={10} />
      <Typography
        variant="h4"
        component="div"
        sx={{ marginBottom: 1, position: "absolute", marginLeft: 5 }}
      >
        Hi {recruiterName}
      </Typography>
      <Typography
       
        component="div"
        sx={{
          marginBottom: 1,
          position: "absolute",
          marginLeft: 5,
          marginTop: 5,
        }}
      >
        Start sourcing your candidate
      </Typography>
      <Container
        style={{
          maxWidth: "70%",
          marginTop: "80px",
        }}
      >
        <Card
          style={{
            backgroundColor: "white",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <CardContent>
            <Grid container justifyContent="left" alignItems="left" spacing={2}>
              <Grid item>
                <AddCircleIcon sx={{ color: "green" }} />
              </Grid>
              <Grid item>
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Create a Resource
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            <form
              onSubmit={handleSubmit}
              style={{ marginTop: 50, marginBottom: 50 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    fullWidth
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Applied Designation"
                    name="appliedDesignation"
                    value={formData.appliedDesignation}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Applied Job Location"
                    name="appliedJobLocation"
                    value={formData.appliedJobLocation}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Current Company"
                    name="currentCompany"
                    value={formData.currentCompany}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Current Designation"
                    name="currentDesignation"
                    value={formData.currentDesignation}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Total Experience"
                    name="totalExp"
                    value={formData.totalExp}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Current CTC"
                    name="currentCtc"
                    value={formData.currentCtc}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Notice Period"
                    name="noticePeriod"
                    value={formData.noticePeriod}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Experience Type</InputLabel>
                    <Select
                      label="Experience Type"
                      name="experienceType"
                      value={formData.experienceType}
                      onChange={handleChange}
                      fullWidth
                    >
                      <MenuItem value="experienced">Experienced</MenuItem>
                      <MenuItem value="fresher">Fresher</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    fullWidth
                    sx={{
                      width: "40%",
                      margin: "auto",
                      backgroundColor: "#423BFA",
                    }} // Center the button
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "UPLOAD CANDIDATE"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={() => setOpen(false)}
              message="Jobseeker created successfully"
            />
          </CardContent>
        </Card>

        <UploadCard />
      </Container>
    </div>
  );
};

export default CreateJobSeeker;
