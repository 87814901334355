import React, { useEffect } from "react";
import Breadcrumbs from "../components/BreadCrumbs"; // Adjust the import path as per your project structure

import AutoGrid from "../components/AutoGrid"; // Adjust the import path as per your project structure

const jwt_decode = require("jwt-decode");

function Dashboard() {
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        console.log(token);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <div style={{marginTop: 50}}>
        <AutoGrid />
      </div>

      {/* Your Dashboard content */}
    </div>
  );
}

export default Dashboard;
