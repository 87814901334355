// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS file */
*{
  font-family: "Roboto", sans-serif;
}

.ant-table-thead > tr > th {
    background-color: #22004C !important;
    color: #fff !important;
    
  }

  .custom-button:hover {
    background-color: #22004c;
    color: white;
    border: none;
  }

  .logo{
    font-size: 25px;
    color: #fff;
    margin: 10px 0px;
  }
  
  .custom-menu{
    background-color: gray;
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/css/main.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,iCAAiC;AACnC;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;;EAExB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* Add this to your CSS file */\r\n*{\r\n  font-family: \"Roboto\", sans-serif;\r\n}\r\n\r\n.ant-table-thead > tr > th {\r\n    background-color: #22004C !important;\r\n    color: #fff !important;\r\n    \r\n  }\r\n\r\n  .custom-button:hover {\r\n    background-color: #22004c;\r\n    color: white;\r\n    border: none;\r\n  }\r\n\r\n  .logo{\r\n    font-size: 25px;\r\n    color: #fff;\r\n    margin: 10px 0px;\r\n  }\r\n  \r\n  .custom-menu{\r\n    background-color: gray;\r\n  }\r\n  \r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
