import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Divider,
} from '@mui/material';

function CreateOrg() {
  const [formData, setFormData] = useState({
    name: '',
    organizationAddress: '',
    orgType: '',
    contactNumber: '',
    email: '',
    adminFullName: '',
    adminEmail: '',
    adminPassword: '',
    adminPhone: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/organization/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage('Organization created successfully!');
      setFormData({
        name: '',
        organizationAddress: '',
        orgType: '',
        contactNumber: '',
        email: '',
        adminFullName: '',
        adminEmail: '',
        adminPassword: '',
        adminPhone: '',
      });
    } catch (error) {
      setMessage(
        error.response?.data?.message ||
          'Error creating organization. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: '30px', marginTop: '30px' }}>
        <Typography variant="h4" gutterBottom align="left" sx={{color:"#423BFA", mb:5}}>
          Create Organization
        </Typography>
        {message && (
          <Alert
            severity={message.includes('successfully') ? 'success' : 'error'}
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Organization Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Organization Address"
                name="organizationAddress"
                value={formData.organizationAddress}
                onChange={handleChange}
                fullWidth
                size="small"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="orgType-label">Organization Type</InputLabel>
                <Select
                  labelId="orgType-label"
                  id="orgType"
                  name="orgType"
                  value={formData.orgType}
                  onChange={handleChange}
                  label="Organization Type"
                >
                  <MenuItem value="company">Company</MenuItem>
                  <MenuItem value="consultant">Consultant</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                size="small"
                required
                inputProps={{
                  pattern: '[0-9]{10}',
                  title: 'Please enter a valid 10-digit contact number',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Company Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                size="small"
                required
                type="email"
              />
            </Grid>
            <Divider sx={{color: "#000", width: 2}}/>
            <Grid item xs={12}>
              <TextField
                label="Admin Full Name"
                name="adminFullName"
                value={formData.adminFullName}
                onChange={handleChange}
                fullWidth
                size="small"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Admin Email"
                name="adminEmail"
                value={formData.adminEmail}
                onChange={handleChange}
                size="small"
                required
                type="email"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Admin Password"
                name="adminPassword"
                value={formData.adminPassword}
                onChange={handleChange}
                size="small"
                required
                type="password"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Admin Phone"
                name="adminPhone"
                value={formData.adminPhone}
                onChange={handleChange}
                size="small"
                required
                inputProps={{
                  pattern: '[0-9]{10}',
                  title: 'Please enter a valid 10-digit phone number',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={isLoading && <CircularProgress size={24} />}
                  sx={{backgroundColor: "#423BFA"}}
                >
                  {isLoading ? 'Creating...' : 'Create Organization'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateOrg;
