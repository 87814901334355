// AutoGrid.js
import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StatCard from "./StatCard"; // Adjust the import path as per your project structure
import StatGraphCard from "./StatGraphCard"; // Adjust the import path as per your project structure
import EqualizerSharpIcon from "@mui/icons-material/EqualizerSharp";
import GetAppSharpIcon from "@mui/icons-material/GetAppSharp";
import StoreSharpIcon from "@mui/icons-material/StoreSharp";
import HowToRegSharpIcon from "@mui/icons-material/HowToRegSharp";

const AutoGrid = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <StatCard
            title="Job Seekers"
            value="281"
            icon={<EqualizerSharpIcon />}
            color="darkblue"
            change="+15%"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title="Recruiters"
            value="2,300"
            icon={<GetAppSharpIcon />}
            color="green"
            change="+10%"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title="Revenue"
            value="$34k"
            icon={<StoreSharpIcon />}
            color="orange"
            change="+8%"
          />
        </Grid>
        <Grid item xs={3}>
          <StatCard
            title="Joinings"
            value="+91"
            icon={<HowToRegSharpIcon />}
            color="blue"
            change="+5%"
          />
        </Grid>
      </Grid>
      <div style={{marginTop: 50}}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <StatGraphCard
              title="Website Views"
              description="Monthly Website Views"
              dataKey="pv"
              stroke="#8884d8"
            />
          </Grid>
          <Grid item xs={4}>
            <StatGraphCard
              title="App Downloads"
              description="Monthly App Downloads"
              dataKey="uv"
              stroke="#82ca9d"
            />
          </Grid>
          <Grid item xs={4}>
            <StatGraphCard
              title="Candidates Recruited"
              description="Monthly Candidates Recruited"
              dataKey="amt"
              stroke="#ffc658"
            />
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default AutoGrid;
