import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/BreadCrumbs';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Divider,
  Grid,
} from '@mui/material';

function OrganizationDetails() {
  const { orgId } = useParams();
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/${orgId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setOrganization(data))
      .catch((error) =>
        console.error('Error fetching organization details:', error)
      );
  }, [orgId]);

  if (!organization) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div>
      <Breadcrumb />
      <Box padding={2}>
        <Typography variant="h4" gutterBottom>
          Organization Details
        </Typography>
        <Divider />

        <Grid container spacing={3} style={{ marginTop: '16px' }}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
                <Typography>
                  <strong>Organization ID:</strong> {organization.organizationId}
                </Typography>
                <Typography>
                  <strong>Name:</strong> {organization.organizationName}
                </Typography>
                <Typography>
                  <strong>Address:</strong> {organization.organizationAddress}
                </Typography>
                <Typography>
                  <strong>Contact Number:</strong> {organization.contactNumber}
                </Typography>
                <Typography>
                  <strong>Email:</strong> {organization.email}
                </Typography>
                <Typography>
                  <strong>Created By:</strong> {organization.creatorFullName}
                </Typography>
                <Typography>
                  <strong>Organization Type:</strong> {organization.orgType}
                </Typography>
                <Typography>
                  <strong>State:</strong> {organization.orgState}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Additional Details
                </Typography>
                <Table component={Paper} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Job Posting Limit</TableCell>
                      <TableCell>Premium Job Posting Limit</TableCell>
                      <TableCell>Is Premium</TableCell>
                      <TableCell>Job Posting Count</TableCell>
                      <TableCell>Total Paid Job Postings</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{organization.jobPostingLimit}</TableCell>
                      <TableCell>
                        {organization.premiumJobPostingLimit}
                      </TableCell>
                      <TableCell>
                        {organization.isPremium ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>{organization.jobPostingCount}</TableCell>
                      <TableCell>{organization.totalPaidJobPostings}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recruiter Admins
                </Typography>
                {organization.recruiterAdmins.length > 0 ? (
                  <Table component={Paper} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Permissions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organization.recruiterAdmins.map((admin) => (
                        <TableRow key={admin._id}>
                          <TableCell>{admin.fullName}</TableCell>
                          <TableCell>{admin.email}</TableCell>
                          <TableCell>{admin.phone}</TableCell>
                          <TableCell>{admin.role}</TableCell>
                          <TableCell>
                            {Object.keys(admin.permissions).map((key) => (
                              <div key={key}>
                                {key}: {admin.permissions[key] ? 'Yes' : 'No'}
                              </div>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>No recruiter admins available</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recruiters
                </Typography>
                {organization.recruiters.length > 0 ? (
                  <Table component={Paper} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {organization.recruiters.map((recruiter) => (
                        <TableRow key={recruiter._id}>
                          <TableCell>{recruiter.fullName}</TableCell>
                          <TableCell>{recruiter.email}</TableCell>
                          <TableCell>{recruiter.phone}</TableCell>
                          <TableCell>{recruiter.role}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>No recruiters available</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default OrganizationDetails;
