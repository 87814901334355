import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import SignIn from "../pages/sign-in";
import Dashboard from "../pages/dashboard";
import JobSeekers from "../pages/jobseekers";
import Recruiters from "../pages/recruiters";
import CreateJobSeeker from "../pages/createJobSeeker";
import Admin from "../pages/adminCRUD";
import MiniDrawer from '../components/sidebar';
import CreateOrg from "../pages/createOrg";
import OrganizationDetails from "../pages/organizationDetails";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route
        path="*"
        element={
          <MiniDrawer>
            <Routes>
              <Route path="/" element={<Navigate to="/signin" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/jobseekers" element={<JobSeekers />} />
              <Route path="/recruiters" element={<Recruiters />} />
              <Route path="/createjobseeker" element={<CreateJobSeeker />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<Navigate to="/signin" />} />
              <Route path="/create_org" element={<CreateOrg />} />
              <Route path="/organization/:orgId" element={<OrganizationDetails />} />
            </Routes>
          </MiniDrawer>
        }
      />
    </Routes>
  );
};

const RouteComponent = () => (
  <Router>
    <AppRoutes />
  </Router>
);

export default RouteComponent;
