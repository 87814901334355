import React, { useState } from "react";
import axios from "axios";
import { Card, Typography, Upload, message, Spin } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Dragger } = Upload;

const UploadCard = () => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (file) => {
    const token = localStorage.getItem("token");
    const upload_url = `${process.env.REACT_APP_BACKEND_URL}/uploadtracker`;

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);

    try {
      const response = await axios.post(upload_url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("File uploaded successfully:", response.data);
      message.success(response.data.message || "File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{
        maxWidth: "75%",
        margin: "auto",
        marginTop: "40px",
        padding: "20px",
        textAlign: "center",
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
        position: "relative",
      }}
    >
      <Spin spinning={loading}>
        <Title level={4}>Upload your Resource Tracker here 👇</Title>
        <Dragger
          name="file"
          multiple={false}
          beforeUpload={handleFileUpload}
          showUploadList={false}
          customRequest={() => {}}
          disabled={loading}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Upload one file at a time.
          </p>
        </Dragger>
      </Spin>
    </Card>
  );
};

export default UploadCard;
