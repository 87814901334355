import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import VisibilityIcon from '@mui/icons-material/Visibility';
import Breadcrumb from "../components/BreadCrumbs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  Button, // Import Button
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

function Recruiters() {
  const [organizations, setOrganizations] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${process.env.REACT_APP_BACKEND_URL}/organization/getallorg`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setOrganizations(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const toggleRow = (orgId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [orgId]: !prevState[orgId],
    }));
  };

  const viewDetails = (orgId) => {
    navigate(`/organization/${orgId}`); // Navigate to details page
  };

  return (
    <div>
      <Breadcrumb />
      <h1>Organizations</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Organization ID</TableCell>
              <TableCell>Organization Name</TableCell>
              <TableCell>Organization Address</TableCell>
              <TableCell>Contact Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((org) => (
              <React.Fragment key={org._id}>
                <TableRow>
                  <TableCell>{org.organizationId}</TableCell>
                  <TableCell>{org.organizationName}</TableCell>
                  <TableCell>{org.organizationAddress}</TableCell>
                  <TableCell>{org.contactNumber}</TableCell>
                  <TableCell>{org.email}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => toggleRow(org._id)}>
                      {expandedRows[org._id] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Button
                      
                      onClick={() => viewDetails(org._id)}
                    >
                      <VisibilityIcon />
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    <Collapse
                      in={expandedRows[org._id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Organization Details
                        </Typography>
                        <Table
                          size="small"
                          aria-label="organization-details"
                          sx={{ width: "50%" }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>Job Posting Limit</TableCell>
                              <TableCell>{org.jobPostingLimit}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Premium Job Posting Limit</TableCell>
                              <TableCell>
                                {org.premiumJobPostingLimit}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Is Premium</TableCell>
                              <TableCell>
                                {org.isPremium ? "Yes" : "No"}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Job Posting Count</TableCell>
                              <TableCell>{org.jobPostingCount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Total Paid Job Postings</TableCell>
                              <TableCell>{org.totalPaidJobPostings}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          style={{ marginTop: "16px" }}
                        >
                          Recruiter Admins
                        </Typography>
                        {org.recruiterAdmins.length > 0 ? (
                          <Table size="small" aria-label="recruiter-admins">
                            <TableHead>
                              <TableRow>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Organization ID</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {org.recruiterAdmins.map((admin) => (
                                <TableRow key={admin._id}>
                                  <TableCell>{admin.fullName}</TableCell>
                                  <TableCell>{admin.email}</TableCell>
                                  <TableCell>{admin.phone}</TableCell>
                                  <TableCell>{admin.role}</TableCell>
                                  <TableCell>{org.organizationId}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <Typography>No recruiter admins available</Typography>
                        )}

                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          style={{ marginTop: "16px" }}
                        >
                          Recruiters
                        </Typography>
                        {org.recruiters.length > 0 ? (
                          <Table size="small" aria-label="recruiters">
                            <TableHead>
                              <TableRow>
                                <TableCell>Full Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Organization ID</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {org.recruiters.map((recruiter) => (
                                <TableRow key={recruiter._id}>
                                  <TableCell>{recruiter.fullName}</TableCell>
                                  <TableCell>{recruiter.email}</TableCell>
                                  <TableCell>{recruiter.phone}</TableCell>
                                  <TableCell>{recruiter.role}</TableCell>
                                  <TableCell>{org.organizationId}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <Typography>No recruiters available</Typography>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Recruiters;
