import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumb">
      <Link component={RouterLink} color="inherit" to="/">
        <HomeIcon style={{ marginRight: 4 }} />
      </Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <Typography
            color="textPrimary"
            key={routeTo}
            sx={{ fontWeight: "bold" }}
          >
            {getPageName(name)}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            color="inherit"
            to={routeTo}
            key={routeTo}
          >
            {getPageName(name)}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
}

function getPageName(name) {
  // Customize page names as needed
  switch (name) {
    case "dashboard":
      return "Dashboard";
    case "jobseekers":
      return "JobSeekers";
    case "recruiters":
      return "Recruiters";
    case "createjobseeker":
      return "Create Job Seeker";
    case "tracker":
      return "Tracker";
    case "admin":
      return "Admin";
    // Add more cases for other pages
    default:
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
}

export default Breadcrumbs;
