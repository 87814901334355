// StatGraphCard.js
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'; // Import necessary components from recharts
import UpdateSharpIcon from '@mui/icons-material/UpdateSharp';

const StatGraphCardContainer = styled(Card)(({ theme }) => ({
  minWidth: 275,
  padding: theme.spacing(2),
  position: 'relative',
  overflow: 'visible',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)', // Enhanced box shadow
}));

const StatGraphTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 500,
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

const StatGraphDescription = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const StatGraphContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  position: 'relative',
  textAlign: 'right',
  color: 'green'
}));

const UpdateIconWrapper = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  verticalAlign: 'middle',
  marginRight: theme.spacing(1),
}));

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#fff', padding: '8px', border: '1px solid #ccc' }}>
        <p>{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const StatGraphLine = styled(Line)(({ theme }) => ({
  stroke: theme.palette.primary.main,
}));

function StatGraphCard({ title, description, dataKey, stroke }) {
  const data = [
    { name: 'Jan', [dataKey]: 4000 },
    { name: 'Feb', [dataKey]: 3000 },
    { name: 'Mar', [dataKey]: 2000 },
    { name: 'Apr', [dataKey]: 2780 },
    { name: 'May', [dataKey]: 1890 },
    { name: 'Jun', [dataKey]: 2390 },
    { name: 'Jul', [dataKey]: 3490 },
    { name: 'Aug', [dataKey]: 2000 },
    { name: 'Sep', [dataKey]: 2780 },
    { name: 'Oct', [dataKey]: 1890 },
    { name: 'Nov', [dataKey]: 2390 },
    { name: 'Dec', [dataKey]: 3490 },
  ];

  return (
    <StatGraphCardContainer>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <StatGraphLine type="monotone" dataKey={dataKey} stroke={stroke} />
        </LineChart>
      </ResponsiveContainer>
      <StatGraphContent>
        <StatGraphTitle>{title}</StatGraphTitle>
        <StatGraphDescription>{description}</StatGraphDescription>
        <Divider />
        <Typography variant="caption" align="right" color="textSecondary">
          <UpdateIconWrapper>
            <UpdateSharpIcon fontSize="small" />
          </UpdateIconWrapper>
           {new Date().toLocaleString()}
        </Typography>
      </StatGraphContent>
    </StatGraphCardContainer>
  );
}

export default StatGraphCard;
