import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Typography,
  Box,
  Paper,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Breadcrumb from "../components/BreadCrumbs";

const JobSeekers = () => {
  const [jobSeekers, setJobSeekers] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredJobSeekers, setFilteredJobSeekers] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("csv");
  const [downloadRange, setDownloadRange] = useState("all");
  const [sourceFilter, setSourceFilter] = useState(""); // Added state for source filter

  useEffect(() => {
    fetchJobSeekers(page, rowsPerPage, sourceFilter);
  }, [page, rowsPerPage, sourceFilter]);

  const fetchJobSeekers = (pageNumber, limit, source = "") => {
    const token = localStorage.getItem("token");
    const api_url = `${process.env.REACT_APP_BACKEND_URL}/jobseekers?page=${pageNumber}&limit=${limit}&source=${source}`;
    setLoading(true);
    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const jobSeekersWithIds = response.data.jobSeekers.map((seeker) => ({
          id: seeker.user?._id || "",
          fullName: seeker.user?.fullName || "",
          email: seeker.user?.email || "",
          phone: seeker.user?.phone || "",
          appliedDesignation: seeker.profile?.appliedDesignation || "",
          appliedJobLocation: seeker.profile?.appliedJobLocation || "",
          currentCompany: seeker.profile?.currentCompany || "",
          currentDesignation: seeker.profile?.currentDesignation || "",
          totalExp: seeker.profile?.totalExp || "",
          currentCtc: seeker.profile?.currentCtc || "",
          expectedCtc: seeker.profile?.expectedCtc || "",
          noticePeriod: seeker.profile?.noticePeriod || "",
          recruiterName: seeker.user?.recruiterName || "",
          sourcedDate: formatDate(seeker.profile?.sourcedDate) || ""
        }));
        setJobSeekers(jobSeekersWithIds);
        setTotal(response.data.total);
        setFilteredJobSeekers(jobSeekersWithIds);
      })
      .catch((error) => {
        console.error("Error fetching job seekers:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchQuery(value);

    const filteredData = jobSeekers.filter((row) =>
      Object.values(row).some(
        (fieldValue) =>
          typeof fieldValue === "string" &&
          fieldValue.toLowerCase().includes(value)
      )
    );
    setFilteredJobSeekers(filteredData);
  };

  const handleDownloadClick = () => {
    setDialogOpen(true);
  };

  const handleDownloadClose = () => {
    setDialogOpen(false);
  };

  const handleDownload = () => {
    let dataToDownload;
    if (downloadRange === "all") {
      dataToDownload = jobSeekers;
    } else if (downloadRange === "current") {
      dataToDownload = jobSeekers.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );
    } else {
      dataToDownload = jobSeekers;
    }

    if (downloadFormat === "csv") {
      downloadCSV(dataToDownload);
    } else {
      downloadPDF(dataToDownload);
    }

    setDialogOpen(false);
  };

  const downloadCSV = (data) => {
    const csvData = data.map((row) => ({
      "Sourced Date": row.sourcedDate,
      "Sourced By": row.recruiterName,
      Name: row.fullName,
      Email: row.email,
      Phone: row.phone,
      "Applied Designation": row.appliedDesignation,
      "Applied Job Location": row.appliedJobLocation,
      "Current Company": row.currentCompany,
      "Current Designation": row.currentDesignation,
      "Total Experience": row.totalExp,
      "Current CTC": row.currentCtc,
      "Expected CTC": row.expectedCtc,
      "Notice Period": row.noticePeriod,
    }));

    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Job Seekers");
    const csvBuffer = XLSX.write(workbook, { bookType: "csv", type: "array" });
    const csvBlob = new Blob([csvBuffer], { type: "text/csv;charset=utf-8;" });
    saveAs(csvBlob, "job_seekers.csv");
  };

  const downloadPDF = (data) => {
    const doc = new jsPDF({ orientation: "landscape" });
    const tableColumn = [
      "Sourced Date",
      "Sourced By",
      "Name",
      "Email",
      "Phone",
      "Applied Designation",
      "Applied Job Location",
      "Current Company",
      "Current Designation",
      "Total Experience",
      "Current CTC",
      "Expected CTC",
      "Notice Period",
     
    ];
    const tableRows = [];

    data.forEach((row) => {
      const rowData = [
        row.sourcedDate, 
        row.recruiterName,
        row.fullName,
        row.email,
        row.phone,
        row.appliedDesignation,
        row.appliedJobLocation,
        row.currentCompany,
        row.currentDesignation,
        row.totalExp,
        row.currentCtc,
        row.expectedCtc,
        row.noticePeriod,
        row.recruiterName,
      ];
      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      theme: "grid",
      styles: { fontSize: 8, cellPadding: 2 },
      headStyles: { fillColor: [22, 160, 133] },
      margin: { top: 10 },
      pageBreak: "auto",
      tableWidth: "auto",
    });

    doc.save("job_seekers.pdf");
  };

  const handleFilterClick = (source) => {
    setSourceFilter(source);
  };

  const columns = [
    { field: "sourcedDate", headerName: "Sourced Date", width: 150 },
    { field: "recruiterName", headerName: "Sourced By", width: 150 },
    { field: "fullName", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "appliedDesignation",
      headerName: "Applied Designation",
      width: 200,
    },
    {
      field: "appliedJobLocation",
      headerName: "Applied Job Location",
      width: 200,
    },
    {
      field: "currentCompany",
      headerName: "Current Company",
      width: 200,
    },
    {
      field: "currentDesignation",
      headerName: "Current Designation",
      width: 250,
    },
    {
      field: "totalExp",
      headerName: "Total Experience",
      width: 150,
    },
    {
      field: "currentCtc",
      headerName: "Current CTC",
      width: 150,
    },
    {
      field: "expectedCtc",
      headerName: "Expected CTC",
      width: 150,
    },
    {
      field: "noticePeriod",
      headerName: "Notice Period",
      width: 150,
    },
    
  ];

  return (
    <Box>
      <Breadcrumb />
      <Paper style={{ margin: "10px", padding: "20px" }}>
        <Typography variant="h6" component="div" gutterBottom>
          JobSeekers
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchInputChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadClick}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleFilterClick("internal")}
          >
            Internal
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleFilterClick("external")}
          >
            External
          </Button>
          
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Box style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={filteredJobSeekers}
                columns={columns}
                pageSize={rowsPerPage}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                pagination
                paginationMode="server" // Server-side pagination
                rowCount={total} // Total number of job seekers
                page={page - 1}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangeRowsPerPage}
                loading={loading}
              />
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Pagination
                count={Math.ceil(total / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                sx={{ mt: 2 }}
              />
            </Box>
          </>
        )}
      </Paper>
      <Dialog open={dialogOpen} onClose={handleDownloadClose}>
        <DialogTitle>Download Options</DialogTitle>
        <DialogContent>
          <DialogContentText>Select the format and range:</DialogContentText>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="format-label">Format</InputLabel>
            <Select
              labelId="format-label"
              value={downloadFormat}
              onChange={(e) => setDownloadFormat(e.target.value)}
            >
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="range-label">Range</InputLabel>
            <Select
              labelId="range-label"
              value={downloadRange}
              onChange={(e) => setDownloadRange(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="current">Current Page</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownloadClose}>Cancel</Button>
          <Button onClick={handleDownload}>Download</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JobSeekers;
